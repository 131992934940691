import './App.css';
import griddy from './griddy.gif'
import griddy2 from './griddy2.png'
import { useRef, useEffect, useState} from 'react';

function App() {

  const videoRef = useRef(null);
  const [griddySource, toggleGriddySource] = useState(true)

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play();
    }
  }, []);

  const links = [
    {
      title: "my pics",
      url: 'https://www.ashank.xyz/bird'
    },
    {
      title: "my mixes",
      url: 'https://www.ashank.xyz/munch'
    },
    {
      title: "my site",
      url: 'https://ashank.xyz'
    },
    // {
    //   title: "my startup",
    //   url: 'https://shape.ci'
    // },

  ]

  const navigateToSite = (url) => {
    window.location.href = url;
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start', paddingTop: '10vh' }}>
      <div style={{ maxWidth: '220px', width: '100%', textAlign: 'left'}}>
        <strong style={{ fontSize: 'small', fontWeight: '600'}}>Ashank Behara </strong>
        <img onClick={() => toggleGriddySource(!griddySource)} src={griddySource ? griddy: griddy2} alt="Griddy" style={{ maxWidth: '100%', borderRadius: '5px', marginTop: "4px", cursor: 'pointer'}} />
        {links.map(({title, url}) => <button onClick={() => navigateToSite(url)} className="pill-button" style={{marginTop: "15px"}}>{title}</button>)}
      </div>
    </div>
  );
}

export default App;